import { useState } from 'react'
import TextInput from '../Input/TextInput';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../Input/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCheck, faShield } from '@fortawesome/free-solid-svg-icons';
import { createNewCardToken } from '../utils';
import { api_postValueOrErrorMessage } from '../../Api/util';
import { PLATFORM } from '../../Hook/platform';

type Props = {
  cb: any,
  button: string
}

function CreditCard(props: Props) {
  const intl = useIntl();
  const [error, setError] = useState("");


  return (
    <div className='flex flex-col gap-4 credit-card'>
      <div id="securionpay-form" className='flex gap-4 my-4 lg:flex-nowrap flex-wrap'>
        <div className='lg:w-1/2 w-full'>
          <TextInput maxLength={16} type="text" name="number" autoComplete='cc-number' label={intl.formatMessage({ id: "payment__card_number" })} />
        </div>
        <div className='flex-1'>
          <TextInput onChange={(event) => {
            if (event.target.value.length <= 2) {
              return
            }
            let current = event.currentTarget.value.replace("/", "");
            if (current.length === 2) return event.currentTarget.value = current;
            event.currentTarget.value = current.substr(0, 2) + "/" + current.substr(2);
          }} maxLength={5} type="text" name="exp" autoComplete="cc-exp" label={intl.formatMessage({ id: "payment__expiration" })} />
        </div>
        <div className='flex-1'>
          <TextInput name="cvc" pattern="\d*" type="text" maxLength={3} autoComplete="cc-csc" label="CVC" />

        </div>
      </div>
      {error !== "" && <div className='text-center text-red-400'>
        <FormattedMessage id={error} />
      </div>}

      <Button className={"!px-8 !py-4  gap-8"} color='primary' onClick={() => {
        setError("")
        createNewCardToken(PLATFORM.paymentprocessor).then(async e => {
          if (e.error) return setError("securion__" + e.error.code);
          let answer = await api_postValueOrErrorMessage("user/cards/add", {
            processor: "securionpay",
            "card_token": e.id
          });
          if(answer.error_message) return setError("securion__" + answer.error_message);
          props.cb();
        })
      }}>
        <FormattedMessage id={props.button} />
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
      <div className='text-xs text-tabs flex gap-4 items-center justify-center'>
        <FontAwesomeIcon className='h-6 w-6 text-green' transform={"shrink-7"} icon={faCheck} mask={faShield} />
        <FormattedMessage id="underage__secured_bank_information" />
      </div>
    </div>
  )
}

export default CreditCard