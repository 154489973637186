import "./App.css";
import usePlatform from "./Hook/platform";
import useUser from "./Hook/user";
import Router from "./Router";
import version from "./version.json"
import { ThemeProvider } from "@material-tailwind/react";
import LanguageProvider from "./LanguageProvider";
import { PROCESS_COLOURS } from "./colours";
import useIsMobile from "./Hook/mobile";
import { useEffect, useState } from "react";
import Button from "./Component/Input/Button";
PROCESS_COLOURS();
const App = () => {
  const platform = usePlatform();
  const user = useUser();
  const detectMobile = useIsMobile();
  const [needReload, setNeedReload] = useState(false);
  useEffect(() => {
    const preventDefault = (event: any) => {
      event.preventDefault();
    };
    document.addEventListener("dragover", preventDefault);
    document.addEventListener("drop", preventDefault);
    const versionCheck = () => {
      if(!(window as any).__webpack_public_path__) return;
      fetch((window as any).__webpack_public_path__ + "version.json").then((res) => res.json()).then((remoteVersion) => {
        console.log(remoteVersion, version);
        if (remoteVersion.version !== version.version) {
          setNeedReload(true);
        }
      })
    }

    setInterval(versionCheck, 1000 * 60 * 10);
    versionCheck();
    return () => {
      document.removeEventListener("dragover", preventDefault);
      document.removeEventListener("drop", preventDefault);
    };
  }, []);
  if (!platform || !user) {
    return (
      <div className="bg-background min-h-screen min-w-screen lg:px-8 px-4"></div>
    );

  }

  return (
    <LanguageProvider>
      <ThemeProvider>
        <div
          id="main-bg"
          className="bg-background min-h-screen min-w-screen max-w-screen overflow-x-hidden lg:px-0 flex flex-col"
        >
          <Router />
          {needReload && <div id="update-popup" className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white rounded-2xl shadow-lg p-6 max-w-sm w-full">
              <h2 className="text-xl font-bold text-gray-800 mb-4">New Version Available</h2>
              <p className="text-gray-600 mb-6">A new version of the website is available. Please reload the page to get the latest updates.</p>
              <div className="flex justify-end">
                <Button color="primary" onClick={() => window.location.reload()}>
                  Reload
                </Button>
              </div>
            </div>
          </div>}
        </div>
      </ThemeProvider>
    </LanguageProvider>
  );
};

export default App;
